import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import PropTypes from 'prop-types';
import React from 'react';
import {
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
  useFirebaseApp,
} from 'reactfire';

export default function ReactFireProvidersWrapper({ children }) {
  const app = useFirebaseApp();
  const functions = getFunctions(app);

  if (process.env.REACT_APP_USE_EMULATOR === 'true') {
    // eslint-disable-next-line no-console
    console.log('Connecting to Functions emulator');
    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
  }

  return (
    <AuthProvider sdk={getAuth(app)}>
      <FirestoreProvider sdk={getFirestore(app)}>
        <StorageProvider sdk={getStorage(app)}>
          <FunctionsProvider sdk={functions}>
            {children}
          </FunctionsProvider>
        </StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
}

ReactFireProvidersWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
